import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import axios from "axios";

export default function useRoomBg() {
  const auth = useSelector((state: RootState) => state.auth);
  const baseUrl: string = `https://node.vip-chat.online/api/v1/super-admin/room-backgrounds/all`;
  const [items, setItems] = useState<{ id: number; name: string }[]>([]);
  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(baseUrl, {
          headers: {
            Authorization: `Bearer ${auth.loginData?.access_token!}`,
            "Content-Type": "application/json",
          },
        });

        // console.log(res.data.data);
        if (res.data) setItems(res.data.data.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [auth.loginData?.access_token, baseUrl]);

  return { items };
}
