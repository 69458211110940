import React, { useState, useEffect, ChangeEvent } from "react";
import { useParams } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import {
  createVipUid,
  editVipUid,
  resetCreateEditVipUidDataError,
} from "../../../store/slices/vipUidsSlice";

// components
import Button from "../../ui/Button";
import Backdrop from "../../models/Backdrop";
import CreateEditModel from "../../models/CreateEditModel";
import Input from "../../ui/Input";

// icons
import { CiEdit } from "react-icons/ci";
import { MdOutlineCreate } from "react-icons/md";
import useCreateVip from "../../../hooks/useCreateVip";
import useRoomBg from "../../../hooks/useRoomBg";
import ImageInput from "../../ui/ImageInput";

const CreateEditSpecialUid = () => {
  const [processType, setProcessType] = useState("create");
  const [showModel, setShowModel] = useState(false);
  const [showWaitMsg, setShowWaitMsg] = useState(false);
  const [hideModel, setHideModel] = useState(false);

  const [image, setImage] = useState<File | null>(null);
  const [bannar, setbannar] = useState<File | null>(null);
  const vipUids = useSelector((state: RootState) => state.vipUids);

  const room_background = useCreateVip({ type: "room_background" });
  const chat_bubble = useCreateVip({ type: "chat_bubble" });
  const entry = useCreateVip({ type: "entry" });
  const frame = useCreateVip({ type: "frame" });
  const RoomBg = useRoomBg();
  const [selectedItems, setSelectedItems] = useState({
    room_background: vipUids.specificVipUidData?.default_background_id || "",
    chat_bubble: vipUids.specificVipUidData?.default_bubble_id || "",
    entry: vipUids.specificVipUidData?.default_entry_id || "",
    frame: vipUids.specificVipUidData?.default_frame_id || "",
    rank: vipUids.specificVipUidData?.name || "bronz",
    mystery_man: vipUids.specificVipUidData?.mystery_man || 0,
    entry_bar: vipUids.specificVipUidData?.entry_bar || "",
  });

  const [formData, setFormData] = useState({
    spicalNumber: "",
    price: "",
    validDays: "",
    comments: "",
  });
  const [formDataError, setFormDataError] = useState({
    spicalNumberError: "",
    priceError: "",
    validDaysError: "",
    commentsError: "",
    bg_imageError: "",
    banar_imageError: "",
  });

  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const auth = useSelector((state: RootState) => state.auth);
  const handelImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
    }
  };
  const handelImagebanar = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setbannar(e.target.files[0]);
    }
  };

  const handelSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedItems({
      ...selectedItems,
      [e.target.name]: e.target.value,
    });
  };

  // render data on inputs from specfic agency data that come from the backend
  useEffect(() => {
    if (processType === "create") return;

    setFormData({
      spicalNumber: vipUids.specificVipUidData?.distinctive_numbers || "",
      price: vipUids.specificVipUidData?.price.toString() || "",
      validDays: vipUids.specificVipUidData?.good_days.toString() || "",
      comments: vipUids.specificVipUidData?.elite_comments.toString() || "",
    });
  }, [vipUids.specificVipUidData, processType, showModel]);

  // useEffect => check if specialUid is created and remove the button message and hide the model
  useEffect(() => {
    if (
      processType === "create" &&
      showWaitMsg &&
      !vipUids.createVipUidLoading
    ) {
      // remove wait msg in the button of create
      setShowWaitMsg(false);
      setHideModel(true);
    }

    if (processType === "edit" && showWaitMsg && !vipUids.editVipUidLoading) {
      // remove wait msg in the button of udpate
      setShowWaitMsg(false);
      setHideModel(true);
    }
  }, [
    vipUids.createVipUidLoading,
    vipUids.editVipUidLoading,
    processType,
    showWaitMsg,
  ]);

  // useEffect to hide modle
  useEffect(() => {
    if (!hideModel) {
      return;
    }
    // empty the input and hide the modle after 2 seconds
    const timer = setTimeout(() => {
      setFormData({
        spicalNumber: "",
        price: "",
        validDays: "",
        comments: "",
      });
      setShowModel(false);
      setHideModel(false);
      // reset data and error of create specialUid => to not render again when create new specialUid
      dispatch(resetCreateEditVipUidDataError());
    }, 2000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideModel]);

  // show and hide backdrop and createEditModel
  const toggleModelHandler = () => {
    setShowModel((prevState) => !prevState);
  };

  // show createModel
  const showCreateModel = () => {
    setProcessType("create");
    toggleModelHandler();
  };

  // show createModel
  const showEditModel = () => {
    setProcessType("edit");
    toggleModelHandler();
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // reset errors
    setFormDataError({
      spicalNumberError: "",
      priceError: "",
      validDaysError: "",
      commentsError: "",
      banar_imageError: "",
      bg_imageError: "",
    });

    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  // createEditModel response => if it true means create Edit specialUid, if false just close model
  const confirmCreateEditHandler = (confirm: boolean) => {
    // check to make sure the user if click more than one time the funcion will not run again
    if (showWaitMsg) {
      return;
    }

    if (confirm) {
      // show message alert the user that the input is empty
      if (!formData.spicalNumber && formData.spicalNumber.trim().length === 0) {
        setFormDataError((prevState) => ({
          ...prevState,
          bodyError: "الرجاء ملئ حقل الجسم (الرقم المميز)",
        }));
        return;
      } else if (!formData.price || formData.price.trim().length === 0) {
        setFormDataError((prevState) => ({
          ...prevState,
          priceError: "الرجاء ملئ حقل السعر",
        }));
        return;
      }

      const submitFormData = new FormData();

      submitFormData.append("name", selectedItems.rank);
      submitFormData.append("default_entry_id", selectedItems.entry);
      submitFormData.append("default_frame_id", selectedItems.frame);
      submitFormData.append("mystery_man", selectedItems.mystery_man);
      submitFormData.append("entry_bar", selectedItems.entry_bar);
      submitFormData.append(
        "default_background_id",
        selectedItems.room_background
      );
      submitFormData.append("good_days", formData.validDays);
      submitFormData.append("elite_comments", formData.comments);
      submitFormData.append("distinctive_numbers", formData.spicalNumber);
      submitFormData.append("price", formData.price);
      submitFormData.append("card_background", image!);
      submitFormData.append("elite_medal", bannar!);
      submitFormData.append("default_bubble_id", selectedItems.chat_bubble);

      // create hosting agency
      if (processType === "create") {
        setShowWaitMsg(true);
        console.log(selectedItems);
        dispatch(
          createVipUid({
            token: auth.loginData?.access_token!,
            formData: submitFormData,
          })
        );
      }

      // edit hosting agency
      if (processType === "edit") {
        setShowWaitMsg(true);
        dispatch(
          editVipUid({
            token: auth.loginData?.access_token!,
            formData: submitFormData,
            vipUid_id: vipUids.specificVipUidData?.id!,
          })
        );
      }

      return;
    }

    // if type is false just close the modle
    toggleModelHandler();
  };

  return (
    <>
      <div className="flex flex-wrap justify-end gap-3 sm:gap-5">
        <Button
          onClick={showCreateModel}
          createBtn
          className="text-xs sm:text-sm p-1.5 px-3"
        >
          <>
            <MdOutlineCreate />
            <span>إنشاء نخبه</span>
          </>
        </Button>
        {vipUids.specificVipUidData?.id && params.id && (
          <Button
            onClick={showEditModel}
            editBtn
            className="text-xs sm:text-sm p-1.5 px-3"
          >
            <>
              <CiEdit />
              <span>تعديل نخبه</span>
            </>
          </Button>
        )}
      </div>

      {/* Model */}
      {showModel && <Backdrop onClose={toggleModelHandler} />}
      <CreateEditModel
        header={
          processType === "create"
            ? "إنشاء نخبه"
            : `تعديل النخبه ${vipUids.specificVipUidData?.distinctive_numbers}`
        }
        type={processType === "create" ? "create" : "edit"}
        createEditBtnContent={
          showWaitMsg
            ? "من فضلك انتظر ..."
            : processType === "create"
            ? "إنشاء نخبه"
            : "تعديل نخبه"
        }
        showCreateEditModel={showModel}
        confirmCreateEditHandler={confirmCreateEditHandler}
      >
        <div className="py-1">
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-10">
            <div>
              <Input
                htmlFor="spicalNumber"
                id="spicalNumber"
                label="الرقم المميز"
                name="spicalNumber"
                onChange={onChange}
                value={formData.spicalNumber}
                placeholder="*(special user id)"
                required
                type="text"
                labelBgColor="bg-gradient-to-b from-grayWhite to-white"
                error={formDataError.spicalNumberError}
              />
            </div>
            <div>
              <Input
                htmlFor="validDays"
                id="validDays"
                label="الايام الصالحه"
                name="validDays"
                onChange={onChange}
                value={formData.validDays}
                placeholder="الايام الصالحه"
                required
                type="number"
                labelBgColor="bg-gradient-to-b from-grayWhite to-white"
                error={formDataError.priceError}
              />
            </div>
            <div>
              <Input
                htmlFor="price"
                id="price"
                label="السعر"
                name="price"
                onChange={onChange}
                value={formData.price}
                placeholder="السعر"
                required
                type="number"
                labelBgColor="bg-gradient-to-b from-grayWhite to-white"
                error={formDataError.priceError}
              />
            </div>
            <div>
              <Input
                htmlFor="comments"
                id="comments"
                label="عدد التعليقات"
                name="price"
                onChange={onChange}
                value={formData.comments}
                placeholder="عدد التعليقات"
                required
                type="number"
                labelBgColor="bg-gradient-to-b from-grayWhite to-white"
                error={formDataError.commentsError}
              />
            </div>

            <div>
              <p className="py-1">اختر خلفيه</p>
              <select
                name="room_background"
                value={selectedItems.room_background}
                onChange={handelSelect}
                className="w-full py-3 focus:outline-lightBlue"
              >
                <option value={""}>null</option>
                {RoomBg &&
                  RoomBg.items.map((R) => (
                    <option key={R.id} value={R.id}>
                      {R.name}
                    </option>
                  ))}
                {room_background.items &&
                  room_background.items.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <p className="py-1">اختر فقاعه</p>
              <select
                name="chat_bubble"
                value={selectedItems.chat_bubble}
                onChange={handelSelect}
                className="w-full py-3 focus:outline-lightBlue"
              >
                <option value={""}>null</option>
                {chat_bubble.items &&
                  chat_bubble.items.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <p className="py-1">اختر اطار</p>
              <select
                name="frame"
                value={selectedItems.frame}
                onChange={handelSelect}
                className="w-full py-3 focus:outline-lightBlue"
              >
                <option value="">null</option>
                {frame.items &&
                  frame.items.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <p className="py-1">الدخول</p>
              <select
                name="entry"
                value={selectedItems.entry}
                onChange={handelSelect}
                className="w-full py-3 focus:outline-lightBlue"
              >
                <option defaultChecked value={""}>
                  null
                </option>
                {entry.items &&
                  entry.items.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <p className="py-1">اسم النخبة</p>
              <select
                name="rank"
                value={selectedItems.rank}
                onChange={handelSelect}
                className="w-full py-3 focus:outline-lightBlue"
              >
                <option value={"bronze"}>bronze</option>
                <option value={"silver"}>silver</option>
                <option value={"gold"}>gold</option>
              </select>
            </div>
            <div>
              <p className="py-1">الرجل الغامض</p>
              <select
                name="mystery_man"
                value={selectedItems.mystery_man}
                onChange={handelSelect}
                className="w-full py-3 focus:outline-lightBlue"
              >
                <option value={0}>false</option>
                <option value={1}>true</option>
              </select>
            </div>

            <div>
              <p className="py-2">شريط الدخول</p>
              <select
                name="entry_bar"
                value={selectedItems.entry_bar}
                onChange={handelSelect}
                className="w-full py-3 focus:outline-lightBlue"
              >
                <option value={0}>false</option>
                <option value={1}>true</option>
              </select>
            </div>
            <div className="col-span-3  flex justify-evenly">
              <div className="flex flex-col sm:flex-row items-center">
                <p className="border-b w-fit mb-4 border-b-lightBlue">
                  خلفيه البطاقه
                </p>
                <div className="scale-75 ">
                  <ImageInput
                    id={2}
                    cover_image={image}
                    cover_imageError={formDataError.bg_imageError}
                    localImage={image}
                    name={"bgImg"}
                    onChange={handelImage}
                  />
                </div>
              </div>

              <div className="flex flex-col sm:flex-row items-center">
                <p className="border-b w-fit mb-4 border-b-lightBlue">
                  وسام النخبه
                </p>
                <div className="scale-75 ">
                  <ImageInput
                    cover_image={bannar}
                    id={1}
                    cover_imageError={formDataError.banar_imageError}
                    localImage={bannar}
                    name={"barImg"}
                    onChange={handelImagebanar}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sm:col-span-2">
            {(vipUids.createVipUidData || vipUids.createVipUidError) && (
              <span
                className={`${
                  vipUids.createVipUidData ? "text-success" : "text-darkRed"
                } text-center mt-5 w-full font-semibold text-sm inline-block`}
              >
                {!vipUids.createVipUidError
                  ? vipUids.createVipUidData?.msg || "لقد تم إنشاء النخبه"
                  : vipUids.createVipUidError.msg || "فشل إنشاء النخبه"}
              </span>
            )}

            {(vipUids.editVipUidData || vipUids.editVipUidError) && (
              <span
                className={`${
                  vipUids.editVipUidData ? "text-success" : "text-darkRed"
                } text-center mt-5 w-full font-semibold text-sm inline-block`}
              >
                {!vipUids.editVipUidError
                  ? vipUids.editVipUidData.msg || "لقد تم تعديل النخبه"
                  : vipUids.editVipUidError.msg || "فشل تعديل النخبه"}
              </span>
            )}
          </div>
        </div>
      </CreateEditModel>
    </>
  );
};

export default CreateEditSpecialUid;
