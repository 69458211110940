import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

import vipUidsServices from "../services/vipUidsServices";

// interfaces
import { Error } from "../../interfaces/public";
import {
  InitialVipUidState,
  AllVipUidsData,
  CreateVipUidData,
  DeleteVipUidData,
  EditVipUidData,
  RemoveVipUidFromUserData,
  SpecificVipUidData,
} from "../../interfaces/store/vipUids";

const initialState: InitialVipUidState = {
  // All special uids
  allVipUidsLoading: false,
  allVipUidsError: null,
  allVipUidsData: null,
  // specific special uid
  specificVipUidLoading: false,
  specificVipUidError: null,
  specificVipUidData: null,
  // create special uid
  createVipUidLoading: false,
  createVipUidError: null,
  createVipUidData: null,
  // update special uid
  editVipUidLoading: false,
  editVipUidError: null,
  editVipUidData: null,
  // delete special uid
  deleteVipUidLoading: false,
  deleteVipUidError: null,
  deleteVipUidData: null,
  // give special uid to user
  giveVipUidToUserLoading: false,
  giveVipUidToUserError: null,
  giveVipUidToUserData: null,
  // remove special uid from user
  removeVipUidFromUserLoading: false,
  removeVipUidFromUserError: null,
  removeVipUidFromUserData: null,
};

// All special uids
export const allVipUids = createAsyncThunk(
  "/super-admin/vip-uids/all",
  async (args: AllVipUidsData, thunkAPI) => {
    try {
      const { page, token } = args;
      return await vipUidsServices.allVipUids(token, page);
    } catch (err: Error) {
      return thunkAPI.rejectWithValue(
        err.response && err.response.data.msg ? err.response.data.msg : err.msg
      );
    }
  }
);

// specific special uid
export const specificVipUid = createAsyncThunk(
  "/super-admin/vip-uids/id",
  async (args: SpecificVipUidData, thunkAPI) => {
    try {
      const { uid, token } = args;
      return await vipUidsServices.specificVipUid(token, uid);
    } catch (err: Error) {
      return thunkAPI.rejectWithValue(
        err.response && err.response.data.msg ? err.response.data.msg : err.msg
      );
    }
  }
);

// create special uid
export const createVipUid = createAsyncThunk(
  "/super-admin/vip-uids/store",
  async (args: CreateVipUidData, thunkAPI) => {
    try {
      const { formData, token } = args;
      return await vipUidsServices.createVipUid(token, formData);
    } catch (err: Error) {
      return thunkAPI.rejectWithValue(
        err.response && err.response.data.msg ? err.response.data.msg : err.msg
      );
    }
  }
);

// update special uid
export const editVipUid = createAsyncThunk(
  "/super-admin/vip-uids/update/id",
  async (args: EditVipUidData, thunkAPI) => {
    try {
      const { formData, token, vipUid_id } = args;
      return await vipUidsServices.editVipUid(token, formData, vipUid_id);
    } catch (err: Error) {
      return thunkAPI.rejectWithValue(
        err.response && err.response.data.msg ? err.response.data.msg : err.msg
      );
    }
  }
);

// delete special uid
export const deleteVipUid = createAsyncThunk(
  "/super-admin/vip-uids/delete/id",
  async (args: DeleteVipUidData, thunkAPI) => {
    try {
      const { token, uid } = args;
      return await vipUidsServices.deleteVipUid(token, uid);
    } catch (err: Error) {
      return thunkAPI.rejectWithValue(
        err.response && err.response.data.msg ? err.response.data.msg : err.msg
      );
    }
  }
);

// remove special uid from user
export const removeVipUidFromUser = createAsyncThunk(
  "/super-admin/vip-uids/remove-from-user/user_id",
  async (args: RemoveVipUidFromUserData, thunkAPI) => {
    try {
      const { token, vipUid_id } = args;
      return await vipUidsServices.removeVipUidFromUser(token, vipUid_id);
    } catch (err: Error) {
      return thunkAPI.rejectWithValue(
        err.response && err.response.data.msg ? err.response.data.msg : err.msg
      );
    }
  }
);

export const vipUidsSlice = createSlice({
  name: "vipUids",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetGiveVipUidToUserDataError: (state) => ({
      ...state,
      giveVipUidToUserData: null,
      giveVipUidToUserError: null,
    }),
    resetCreateEditVipUidDataError: (state) => ({
      ...state,
      createVipUidData: null,
      createVipUidError: null,
      editVipUidData: null,
      editVipUidError: null,
    }),
  },
  extraReducers: (builder) => {
    builder
      // All Rooms
      .addCase(allVipUids.pending, (state) => {
        state.allVipUidsLoading = true;
      })
      .addCase(allVipUids.fulfilled, (state, action) => {
        state.allVipUidsLoading = false;
        const { data } = action.payload;
        state.allVipUidsData = data;
      })
      .addCase(allVipUids.rejected, (state, action) => {
        state.allVipUidsLoading = false;
        state.allVipUidsError = action.payload;
      })
      // specific special uid
      .addCase(specificVipUid.pending, (state) => {
        state.specificVipUidLoading = true;
      })
      .addCase(specificVipUid.fulfilled, (state, action) => {
        state.specificVipUidLoading = false;
        const { data } = action.payload;
        state.specificVipUidData = data;
      })
      .addCase(specificVipUid.rejected, (state, action) => {
        state.specificVipUidLoading = false;
        state.specificVipUidError = action.payload;
      })
      // create special uid
      .addCase(createVipUid.pending, (state) => {
        state.createVipUidLoading = true;
      })
      .addCase(createVipUid.fulfilled, (state, action) => {
        state.createVipUidLoading = false;
        const { data } = action.payload;
        state.createVipUidData = data;
      })
      .addCase(createVipUid.rejected, (state, action) => {
        state.createVipUidLoading = false;
        state.createVipUidError = action.payload;
      })
      // update special uid
      .addCase(editVipUid.pending, (state) => {
        state.editVipUidLoading = true;
      })
      .addCase(editVipUid.fulfilled, (state, action) => {
        state.editVipUidLoading = false;
        const { data, formData } = action.payload;
        state.editVipUidData = data;

        // edit special uid
        const body = formData.get("body");
        const price = formData.get("price");
        const currentState: InitialVipUidState = current(state);

        const newSpecificSpecialUid = {
          ...currentState.specificVipUidData,
          price: price ? +price : currentState.specificVipUidData?.price,
          body: body ? +body : currentState.specificVipUidData?.body,
        };

        // @ts-ignore
        state.specificSpecialUidData = newSpecificSpecialUid;
      })
      .addCase(editVipUid.rejected, (state, action) => {
        state.editVipUidLoading = false;
        state.editVipUidError = action.payload;
      })
      // delete special uid
      .addCase(deleteVipUid.pending, (state) => {
        state.deleteVipUidLoading = true;
      })
      .addCase(deleteVipUid.fulfilled, (state, action) => {
        state.deleteVipUidLoading = false;
        const { data, vipUid_id } = action.payload;
        state.deleteVipUidData = data;

        // delete special uid => specifc uid page

        // @ts-ignore
        state.specificSpecialUidData = null;

        // delete special uid => uids page
        const currentState: InitialVipUidState = current(state);

        const filteredData = [...currentState.allVipUidsData?.data!].filter(
          (one) => one.id !== +vipUid_id
        );

        const newAllVipUid = {
          ...currentState.allVipUidsData,
          data: filteredData,
        };

        // @ts-ignore
        state.allVipUidsData = newAllVipUid;
      })
      .addCase(deleteVipUid.rejected, (state, action) => {
        state.deleteVipUidLoading = false;
        state.deleteVipUidError = action.payload;
      })

      // remove special uid from user
      .addCase(removeVipUidFromUser.pending, (state) => {
        state.removeVipUidFromUserLoading = true;
      })
      .addCase(removeVipUidFromUser.fulfilled, (state, action) => {
        state.removeVipUidFromUserLoading = false;
        const { data } = action.payload;
        state.removeVipUidFromUserData = data;

        // remove specialUid from user
        const currentState: InitialVipUidState = current(state);
        const newSpecificSpecialUid = {
          ...currentState.specificVipUidData,
          is_purchased: 0,
          user: null,
        };

        // @ts-ignore
        state.specificSpecialUidData = newSpecificSpecialUid;
      })
      .addCase(removeVipUidFromUser.rejected, (state, action) => {
        state.removeVipUidFromUserLoading = false;
        state.removeVipUidFromUserError = action.payload;
      });
  },
});

export const {
  reset,
  resetGiveVipUidToUserDataError,
  resetCreateEditVipUidDataError,
} = vipUidsSlice.actions;
export default vipUidsSlice.reducer;
