import { useState } from "react";
import useGetRewords from "../../hooks/useGetRewords";
import { RewardsType } from "../../interfaces/pages/rewards";
import Backdrop from "../models/Backdrop";
import RewardsTabel from "./table/RewardsTabel";
import CreateEditRewords from "./manipulation/CreateEditRewords";

export default function Folwers() {
  const [showModel, setShowModel] = useState(false);
  const { error, items, loading } = useGetRewords({
    type: RewardsType.FOLLOWERS,
  });

  // show and hide backdrop and createEditModel
  const toggleModelHandler = () => {
    setShowModel((prevState) => !prevState);
  };

  return (
    <>
      {showModel && <Backdrop onClose={toggleModelHandler} />}{" "}
      <div className="mt-4">
        <CreateEditRewords name={"متابعين"} processType={"create"} />
      </div>
      <RewardsTabel
        type={RewardsType.FOLLOWERS}
        loading={loading}
        items={items}
        error={error}
      />
    </>
  );
}
