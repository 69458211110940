import { useState } from "react";
import Button from "../../ui/Button";
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineLink } from "react-icons/ai";
import Backdrop from "../../models/Backdrop";
import ConfirmDelete from "../../models/ConfirmDelete";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const RewardsTabelBody = ({ items }: { items: any }) => {
  const { pathname } = useLocation();
  const { loginData } = useSelector((s: RootState) => s.auth);
  const [allItems, setAllItems] = useState<{ id: number }[]>(items.data);

  const [showDeleteModel, setShowDeleteModel] = useState({
    type: false,
    numberId: 0,
  });

  console.log(allItems);

  // fun => show delete model
  const deleteRewordHandler = async (numberId: number) => {
    setShowDeleteModel({ type: true, numberId: numberId });
  };

  // fun => hide delete model
  const closeDeleteModelHandler = () => {
    setShowDeleteModel({ type: false, numberId: 0 });
  };

  // confirm delete => if the fun return true means delete number, if it false just close the model
  const confirmDeleteHandler = async (deletenumber: boolean) => {
    if (deletenumber) {
      // delete reword
      try {
        await axios.post(
          `https://node.vip-chat.online/api/v1/super-admin/rewards/delete/${showDeleteModel.numberId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${loginData.access_token}`,
            },
          }
        );
        setAllItems((prev) =>
          prev.filter((i) => i.id !== showDeleteModel.numberId)
        );
      } catch (error) {}

      closeDeleteModelHandler();
    } else {
      // close the model
      closeDeleteModelHandler();
    }
  };

  return (
    <>
      {items.data && allItems ? (
        <tbody className="font-light text-sm">
          {allItems.map((item: any, i: number) => (
            <tr
              key={i}
              className={`border-b-[1px] hover:bg-white/30 border-white`}
            >
              <td className="capitalize text-center py-3 pl-1 sm:pl-3 text-[10px] sm:text-base border-r-[1px] border-white">
                {i + 1 + items.per_page! * (+items.current_page! - 1)}
              </td>
              <td className="capitalize text-center py-3 pl-1 sm:pl-3 text-[10px] sm:text-base border-r-[1px] border-white">
                {item.number}
              </td>

              <td className="capitalize text-center py-3 pl-1 sm:pl-3 text-[10px] sm:text-base border-r-[1px] border-white">
                {item.coin}
              </td>

              <td className="py-3 pl-1 sm:pl-3 text-[10px] sm:text-base border-l-[1px] border-r-[1px] border-white">
                <h6
                  className={`text-center font-bold ${
                    item.status ? "text-success" : "text-darkRed"
                  }`}
                >
                  {item.status ? "avilable" : "unavilable"}
                </h6>
              </td>
              <td className="py-3 pl-1 sm:pl-3 text-[10px] sm:text-base border-l-[1px] border-r-[1px] border-white">
                <Button
                  className="mx-auto text-xs p-1 sm:p-1.5 font-normal sm:text-sm"
                  deleteBtn
                  onClick={() => deleteRewordHandler(item.id)}
                >
                  <RiDeleteBin5Line />
                </Button>
              </td>

              <td className=" py-3 pl-1 sm:pl-3 text-[10px] sm:text-base border-l-[1px] border-white">
                <Button
                  type="link"
                  className="text-xs mx-auto pb-1.5 p-1 rounded-md sm:text-sm bg-lightBlue text-white"
                  to={`${pathname}/${item.id}`}
                >
                  <AiOutlineLink />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      ) : (
        <tbody></tbody>
      )}
      {showDeleteModel.type && <Backdrop onClose={closeDeleteModelHandler} />}
      <tfoot>
        <tr>
          <td>
            <ConfirmDelete
              deleteBtnContent={false ? "الرجاء الانتظار..." : "أحذف"}
              showDeleteModel={showDeleteModel.type}
              confirmDeleteHandler={confirmDeleteHandler}
              header={"هل انت متاكد من حذف الرقم ذو الرقم التعريفى"}
              element={showDeleteModel.numberId.toString() || ""}
              text="عند حذفك لهذ الرقم المميز لايمكنك استرجاعه مره اخرى هل انت متاكد من انك تريد حذفه"
            />
          </td>
        </tr>
      </tfoot>
    </>
  );
};

export default RewardsTabelBody;
