import axios from "axios";

// interfaces
import { LoginFormData } from "../../interfaces/store/auth";

// Login
const login = async (formData: LoginFormData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const { data } = await axios.post(
    `https://node.vip-chat.online/api/v1/super-admin/auth`,
    formData,
    config
  );
  return data;
};

// Logout
const logout = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.post(
    `https://node.vip-chat.online/api/v1/super-admin/logout`,
    {},
    config
  );
  return data;
};

// me
const me = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.post(
    `https://node.vip-chat.online/api/v1/super-admin/me`,
    {},
    config
  );
  return data;
};

const authServices = {
  login,
  logout,
  me,
};

export default authServices;
