import React from "react";

// components
import CreateEditSpecialUid from "./CreateEditVipUid";
import DeletespecialUid from "./DeleteVipUid";
import RemoveUserIdFromUser from "./RemoveUserIdFromUser";

const Manipulaton = () => {
  return (
    <div className="flex flex-wrap justify-end gap-3 sm:gap-5 mt-5">
      <CreateEditSpecialUid />
      <DeletespecialUid />
      <RemoveUserIdFromUser />
    </div>
  );
};

export default Manipulaton;
