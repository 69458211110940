import { useLocation } from "react-router-dom";
import Spinner from "../../ui/spinner/Spinner";
import Message from "../../ui/Message";
import RewardsTabelHeader from "./RewardsTabelHeader";
import RewardsTabelBody from "./RewardsTabelBody";
import Pagination from "../../ui/pagination/Pagination";
import { RewardsType } from "../../../interfaces/pages/rewards";

const RewardsTabel = ({
  type,
  loading,
  items,
  error,
}: {
  type: RewardsType.HOURS | RewardsType.CHARGES | RewardsType.FOLLOWERS;
  loading: boolean;
  error: string;
  items: any;
}) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const page = query.get("page");

  return loading ? (
    <Spinner />
  ) : error ? (
    <Message>{error}</Message>
  ) : 10 ? (
    <>
      <div className="flex mt-10 mb-5 justify-end gap-2 font-semibold text-success/80 text-xs">
        <span>عدد المكافأت:</span>
        <span>{items && items.total}</span>
      </div>

      <table className="w-full">
        <RewardsTabelHeader />
        <RewardsTabelBody items={items} />
      </table>

      {items.total ? (
        <Pagination
          total_pages={items && items.total}
          items_per_page={items && items.per_page}
        />
      ) : null}
    </>
  ) : (
    <div className="mt-5">
      <Message>لا يوجد المكافأت.</Message>
    </div>
  );
};

export default RewardsTabel;
