import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function useGetRewords({ type }: { type: string }) {
  const auth = useSelector((state: RootState) => state.auth);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const page = query.get("page");

  console.log({ page });

  const baseUrl: string = `https://node.vip-chat.online/api/v1/super-admin/rewards/${type}?page=${
    page ?? 1
  }`;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setErorr] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await axios.get(baseUrl, {
          headers: {
            Authorization: `Bearer ${auth.loginData?.access_token!}`,
            "Content-Type": "application/json",
          },
        });

        if (res.data) setItems(res.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (axios.isAxiosError(error)) {
          if (error.response?.data.message) {
            setErorr(error.response.data.message);
          } else {
            setErorr(error.message);
          }
        }
        console.log(error);
      }
    })();
  }, [auth.loginData?.access_token, baseUrl, type, page]);

  return { loading, error, items };
}
