import axios from "axios";

// create video target
const createVideoTarget = async (token: string, formData: Object) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const data = await axios.post(
    `https://node.vip-chat.online/api/v1/super-admin/hosting-agency-video-targets/store`,
    formData,
    config
  );

  return data;
};

// update video target
const updateVideoTarget = async (
  token: string,
  target_id: string,
  formData: FormData
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const data = await axios.put(
    `https://node.vip-chat.online/api/v1/super-admin/hosting-agency-video-targets/update/${target_id}`,
    formData,
    config
  );

  return { data, formData };
};

// all video targets
const allVideoTargets = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.get(
    `https://node.vip-chat.online/api/v1/super-admin/hosting-agency-video-targets/all`,
    config
  );

  return data;
};

// specifc video target
const specifcVideoTarget = async (token: string, target_id: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.get(
    `https://node.vip-chat.online/api/v1/super-admin/hosting-agency-video-targets/show/${target_id}`,
    config
  );

  return data;
};

const VideoTarget_hostingAgenciesServices = {
  createVideoTarget,
  updateVideoTarget,
  allVideoTargets,
  specifcVideoTarget,
};

export default VideoTarget_hostingAgenciesServices;
