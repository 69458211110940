import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { specificVipUid } from "../../../store/slices/vipUidsSlice";

// components
import PagesHeaders from "../../ui/PagesHeaders";
import Spinner from "../../ui/spinner/Spinner";
import Message from "../../ui/Message";
import SpecificVipUidUi from "./SpecificVipUidUi";

const SpecificVipUid = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { id } = useParams();
  const auth = useSelector((state: RootState) => state.auth);
  const vipUids = useSelector((state: RootState) => state.vipUids);

  useEffect(() => {
    dispatch(
      specificVipUid({
        uid: id!,
        token: auth.loginData?.access_token!,
      })
    );
  }, [auth.loginData?.access_token, dispatch, id]);

  //   console.log(vipUids.specificSpecialUidData);

  return (
    <section className="container mx-auto h-full px-2 pt-5 flex flex-col">
      <PagesHeaders>الارقام النخبه</PagesHeaders>

      {vipUids.specificVipUidLoading ? (
        <Spinner />
      ) : vipUids.specificVipUidError ? (
        <Message>{vipUids.specificVipUidError}</Message>
      ) : vipUids.specificVipUidData ? (
        <>
          <SpecificVipUidUi
            name={vipUids.specificVipUidData.name}
            created_at={vipUids.specificVipUidData.created_at}
            id={vipUids.specificVipUidData.id}
            is_purchased={vipUids.specificVipUidData.is_purchased}
            price={vipUids.specificVipUidData.price}
            updated_at={vipUids.specificVipUidData.updated_at}
            user={vipUids.specificVipUidData.user}
            user_id={vipUids.specificVipUidData.user_id}
          />
        </>
      ) : (
        <Message>الرقم المميز غير موجود</Message>
      )}
    </section>
  );
};

export default SpecificVipUid;
