import { useParams } from "react-router-dom";
import RewardsTabel from "./table/RewardsTabel";
import { RewardsType } from "../../interfaces/pages/rewards";
import useGetRewords from "../../hooks/useGetRewords";
import CreateEditRewords from "./manipulation/CreateEditRewords";

export default function Hours() {
  const { type } = useParams();

  const { error, items, loading } = useGetRewords({ type: RewardsType.HOURS });

  console.log(items);

  return (
    <>
      <div className="mt-4">
        <CreateEditRewords name={"الساعات"} processType={"create"} />
      </div>
      <RewardsTabel
        type={RewardsType.HOURS}
        loading={loading}
        items={items}
        error={error}
      />
    </>
  );
}
