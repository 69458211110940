import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// components
import Button from "../../ui/Button";
import Backdrop from "../../models/Backdrop";
import ConfirmDelete from "../../models/ConfirmDelete";

// icons
import { RiDeleteBin6Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import axios from "axios";

const DeleteReword = () => {
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const { loginData } = useSelector((s: RootState) => s.auth);
  const navigate = useNavigate();

  const { id } = useParams();

  // useEffect => check if the specialUid is deleted close the model

  const toggleDeleteModelHandler = () => {
    setShowDeleteModel((prevState) => !prevState);
  };

  // confirm delete user handler
  const confirmDeleteHandler = async (type: boolean) => {
    // if server is loading delete specialUid make sure not send any more requests
    if (type) {
      try {
        await axios.post(
          `https://node.vip-chat.online/api/v1/super-admin/rewards/delete/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${loginData.access_token}`,
            },
          }
        );
        navigate(-1);
      } catch (error) {}
    }
    // if type is false means close the model
    toggleDeleteModelHandler();
  };

  return (
    <>
      {id && (
        <>
          <Button
            onClick={toggleDeleteModelHandler}
            deleteBtn
            className="text-xs sm:text-sm p-1.5 px-3"
          >
            <>
              <RiDeleteBin6Line />
              <span>مسح المكافأه</span>
            </>
          </Button>
          {showDeleteModel && <Backdrop onClose={toggleDeleteModelHandler} />}
          <ConfirmDelete
            deleteBtnContent={false ? "الرجاء الانتظار..." : "أحذف"}
            showDeleteModel={showDeleteModel}
            confirmDeleteHandler={confirmDeleteHandler}
            header={"هل انت متاكد من حذف المكافأه"}
            element={id || ""}
            text="عند حذفك لهذ المكافأه لا يمكنك استرجاعه مره اخرى. ولاكن هل انت متاكد من انك تريد حذفه"
          />
        </>
      )}
    </>
  );
};

export default DeleteReword;
