import axios from "axios";

// All vip uids
const allVipUids = async (token: string, page: string | number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.get(
    `https://node.vip-chat.online/api/v1/super-admin/vips/all?page=${
      page ? page : 1
    }`,
    config
  );
  return data;
};

// specific vip uid
const specificVipUid = async (token: string, uid: string | number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.get(
    `https://node.vip-chat.online/api/v1/super-admin/vips/${uid}`,
    config
  );
  return data;
};

// create vip uid
const createVipUid = async (token: string, formData: object) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const data = await axios.post(
    `https://node.vip-chat.online/api/v1/super-admin/vips/store`,
    formData,
    config
  );
  return data;
};

// update vip uid
const editVipUid = async (
  token: string,
  formData: FormData,
  vipUid_id: string | number
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const data = await axios.post(
    `https://node.vip-chat.online/api/v1/super-admin/vips/update/${vipUid_id}`,
    formData,
    config
  );
  return { data, formData };
};

// delete vip uid
const deleteVipUid = async (token: string, vipUid_id: string | number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const data = await axios.post(
    `https://node.vip-chat.online/api/v1/super-admin/vips/delete/${vipUid_id}`,
    {},
    config
  );
  return { data, vipUid_id };
};

// remove vip uid from user
const removeVipUidFromUser = async (
  token: string,
  user_id: string | number
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const data = await axios.post(
    `https://node.vip-chat.online/api/v1/super-admin/vips/delete/${user_id}`,
    {},
    config
  );
  return data;
};

const vipUidsServices = {
  allVipUids,
  specificVipUid,
  createVipUid,
  editVipUid,
  deleteVipUid,
  removeVipUidFromUser,
};

export default vipUidsServices;
