import { useParams } from "react-router-dom";
import { RewardsType } from "../interfaces/pages/rewards";
import Hours from "../components/rewards/Hours";
import Folwers from "../components/rewards/Folwers";
import NotFound from "./404";
import Button from "../components/ui/Button";
import PagesHeaders from "../components/ui/PagesHeaders";
import Charges from "../components/rewards/Charges";
import { FaClockRotateLeft, FaPeopleGroup } from "react-icons/fa6";
import { BsFillLightningChargeFill } from "react-icons/bs";

export default function Rewards() {
  const { type } = useParams();

  return (
    <div className="m-5">
      <PagesHeaders>المكافأت</PagesHeaders>
      <div className="flex gap-5 [&_*]:bg-success text-white justify-end">
        <Button type="link" to={`/rewards/${RewardsType.HOURS}`}>
          <FaClockRotateLeft /> مكافأة الساعات
        </Button>
        <Button type="link" to={`/rewards/${RewardsType.CHARGES}`}>
          <BsFillLightningChargeFill /> مكافأة الشحن
        </Button>
        <Button type="link" to={`/rewards/${RewardsType.FOLLOWERS}`}>
          <FaPeopleGroup /> مكافأة المتابعين
        </Button>
      </div>
      {type === RewardsType.HOURS ? (
        <Hours />
      ) : type === RewardsType.CHARGES ? (
        <Charges />
      ) : type === RewardsType.FOLLOWERS ? (
        <Folwers />
      ) : (
        <NotFound />
      )}
    </div>
  );
}
