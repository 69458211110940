import axios from "axios";

// create audio target
const createAudioTarget = async (token: string, formData: Object) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const data = await axios.post(
    `https://node.vip-chat.online/api/v1/super-admin/hosting-agency-targets/store`,
    formData,
    config
  );

  return data;
};

// update audio target
const updateAudioTarget = async (
  token: string,
  target_id: string,
  formData: FormData
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const data = await axios.put(
    `https://node.vip-chat.online/api/v1/super-admin/hosting-agency-targets/update/${target_id}`,
    formData,
    config
  );

  return { data, formData };
};

// all audio targets
const allAudioTargets = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
    },
  };

  const { data } = await axios.get(
    `https://node.vip-chat.online/api/v1/super-admin/hosting-agency-targets/all`,
    config
  );

  return data;
};

// specifc audio target
const specificAudioTarget = async (token: string, target_id: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.get(
    `https://node.vip-chat.online/api/v1/super-admin/hosting-agency-targets/show/${target_id}`,
    config
  );

  return data;
};

const audioTarget_hostingAgenciesServices = {
  createAudioTarget,
  updateAudioTarget,
  allAudioTargets,
  specificAudioTarget,
};

export default audioTarget_hostingAgenciesServices;
