import { useParams } from "react-router-dom";

import { ChangeEvent, useEffect, useState } from "react";
import Backdrop from "../../models/Backdrop";
import CreateEditModel from "../../models/CreateEditModel";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { IoMdCreate } from "react-icons/io";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import useShowReword from "../../../hooks/useShowReword";

export default function CreateEditRewords({
  processType,
  name,
}: {
  name: string;
  processType: string;
}) {
  const [showModel, setShowModel] = useState(false);
  const [showWaitMsg, setShowWaitMsg] = useState(false);
  const { type, id } = useParams();
  const { loginData } = useSelector((s: RootState) => s.auth);
  const { items } = useShowReword({ id: id ? +id : 0 });

  const [formData, setFormData] = useState({
    hours: "",
    coins: "",
  });

  useEffect(() => {
    if (items) {
      setFormData({
        hours: String(items.number),
        coins: String(items.coin),
      });
      setSelected({
        status: items.status,
      });
    }
  }, [items]);
  const [selected, setSelected] = useState<{ status: number }>({
    status: 0,
  });

  const [formDataError, setFormDataError] = useState({
    hoursError: "",
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: +e.target.value }));
  };

  const handelSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelected({ status: +e.target.value });
  };

  const confirmCreateEditHandler = async (confirm: boolean) => {
    if (confirm) {
      const payload = {
        number: formData.hours,
        coin: formData.coins,
        type,
        status: selected.status,
      };

      // ############ call API
      try {
        if (payload.coin && payload.number) {
          if (processType === "create") {
            setShowWaitMsg(true);
            const { data } = await axios.post(
              `https://node.vip-chat.online/api/v1/super-admin/rewards/store`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${loginData.access_token}`,
                },
              }
            );
            setFormData({
              coins: "",
              hours: "",
            });
            setShowWaitMsg(false);
          } else {
            console.log("edite");
            setShowWaitMsg(true);

            const { data } = await axios.post(
              `https://node.vip-chat.online/api/v1/super-admin/rewards/update/${id}`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${loginData.access_token}`,
                },
              }
            );
            console.log(data);
            setShowWaitMsg(false);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    // ############ end call API

    toggleModelHandler();
  };
  // show and hide backdrop and createEditModel
  const toggleModelHandler = () => {
    setShowModel((prevState) => !prevState);
  };

  return (
    <>
      <div className="flex justify-end">
        {processType === "create" ? (
          <Button createBtn onClick={() => setShowModel(true)}>
            <IoMdCreate /> إنشاء مكافأة {name}
          </Button>
        ) : (
          <Button editBtn onClick={() => setShowModel(true)}>
            <IoMdCreate /> تعديل مكافأة {name}
          </Button>
        )}
      </div>
      {showModel && <Backdrop onClose={toggleModelHandler} />}
      {showModel && <Backdrop onClose={toggleModelHandler} />}{" "}
      <CreateEditModel
        header={
          processType === "create"
            ? `إنشاء مكافأه ${name} `
            : `تعديل مكافأه  ${name}`
        }
        type={processType === "create" ? "create" : "edit"}
        createEditBtnContent={
          showWaitMsg
            ? "من فضلك انتظر ..."
            : processType === "create"
            ? "إنشاء مكافأه "
            : "تعديل مكافأه"
        }
        showCreateEditModel={showModel}
        confirmCreateEditHandler={confirmCreateEditHandler}
      >
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-10">
          <div>
            <Input
              htmlFor="hours"
              id="hours"
              label={`عدد ${name}`}
              name="hours"
              onChange={onChange}
              value={formData.hours}
              placeholder="*(hours)"
              required
              type="number"
              labelBgColor="bg-gradient-to-b from-grayWhite to-white"
              error={formDataError.hoursError}
            />
          </div>

          <div>
            <Input
              htmlFor="coins"
              id="coins"
              label="عدد العملات"
              name="coins"
              onChange={onChange}
              value={formData.coins}
              placeholder="*(coins)"
              required
              type="number"
              labelBgColor="bg-gradient-to-b from-grayWhite to-white"
              error={formDataError.hoursError}
            />
          </div>

          <div>
            <p className="py-1">اختر خلفيه</p>
            <select
              name="room_background"
              value={selected.status}
              onChange={handelSelect}
              className="w-full py-3 focus:outline-lightBlue"
            >
              <option value={1}>available</option>
              <option value={0}>unavailable</option>
            </select>
          </div>
        </div>
      </CreateEditModel>
    </>
  );
}
