import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import axios from "axios";

export default function useShowReword({ id }: { id: number }) {
  const auth = useSelector((state: RootState) => state.auth);

  const baseUrl: string = `https://node.vip-chat.online/api/v1/super-admin/rewards/${id}`;
  const [items, setItems] = useState<{
    coin: number;
    created_at: string;
    id: number;
    number: number;
    status: number;
    type: "hours";
    updated_at: string;
  }>();
  const [loading, setLoading] = useState(false);
  const [error, setErorr] = useState("");

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          setLoading(true);
          const res = await axios.get(baseUrl, {
            headers: {
              Authorization: `Bearer ${auth.loginData?.access_token!}`,
              "Content-Type": "application/json",
            },
          });

          if (res.data) setItems(res.data.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (axios.isAxiosError(error)) {
            if (error.response?.data.message) {
              setErorr(error.response.data.message);
            } else {
              setErorr(error.message);
            }
          }
          console.log(error);
        }
      })();
    }
  }, [auth.loginData?.access_token, baseUrl, id]);

  return { loading, error, items };
}
