import { useParams } from "react-router-dom";
import PagesHeaders from "../components/ui/PagesHeaders";
import { RewardsType } from "../interfaces/pages/rewards";
import DeleteReword from "../components/rewards/manipulation/DeleteReword";
import CreatedAt from "../components/helpers/CreatedAt";
import useShowReword from "../hooks/useShowReword";
import Spinner from "../components/ui/spinner/Spinner";
import Button from "../components/ui/Button";
import CreateEditRewords from "../components/rewards/manipulation/CreateEditRewords";

export default function ViewReword() {
  const { id, type } = useParams();
  const { items } = useShowReword({ id: id ? +id : 0 });
  console.log(items);

  const name =
    type === RewardsType.HOURS
      ? "الساعات"
      : RewardsType.CHARGES
      ? "الشحن"
      : RewardsType.FOLLOWERS
      ? "المتابعين "
      : "";
  return (
    <div className="m-5">
      <PagesHeaders>المكافأت</PagesHeaders>

      {items ? (
        <>
          <div className="flex gap-4 justify-end items-center">
            <CreateEditRewords name={name} processType={"create"} />
            <CreateEditRewords name={name} processType={"edit"} />
            <DeleteReword />
          </div>

          <div className="grid grid-cols-3 w-11/12 md:w-2/4 my-12 mx-auto">
            <Button className="p-4 flex flex-col">
              <span>number</span>
              {items.number}
            </Button>
            <Button className="p-4 flex flex-col">
              <span>coins</span>
              {items.coin}
            </Button>
            <Button className="p-4 flex flex-col">
              <span>status</span>
              <span
                className={`${
                  items.status ? "bg-success" : "bg-darkRed"
                } p-3 rounded-full`}
              ></span>
            </Button>
          </div>

          <div className="flex flex-col flex-1 justify-items-end">
            <div className="flex bg-success p-0.5 px-2 w-fit text-white rounded-md justify-end items-center gap-1 font-semibold text-sm hover:scale-95 duration-150">
              <span>تم إنشاؤه في</span>
              <span className="tracking-tighter">
                <CreatedAt createdAt={items.created_at} />
              </span>
            </div>

            <div className="flex mt-5 bg-stars p-0.5 px-2 w-fit text-white rounded-md justify-end items-center gap-1 font-semibold text-sm hover:scale-95 duration-150">
              <span>تم تعديله في</span>
              <span className="tracking-tighter">
                <CreatedAt createdAt={items.updated_at} />
              </span>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
